import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';

const detectionOptions = {
    order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    caches: ['localStorage', 'cookie']
}

registerLocale('pl', pl);
setDefaultLocale('pl');

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: detectionOptions,
        debug: true,
        fallbackLng: 'pl',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        ns: 'common',
        defaultNS: 'common',
        fallbackNS: 'common',
        load: 'languageOnly',

        // pluralSeparator: ':',
        simplifyPluralSuffix: false
    });

export default i18n;
