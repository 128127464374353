import React, {useEffect, useRef, useState} from "react";
import useRest from "../../utils/rest";
import {useEventListener, useVersion} from "../../utils/hooks";
import {MutableRefObject} from "react";
import ChangeLogPopup from "./parts/ChangeLogPopup";
import extractChanges from "./extractChanges";

interface StoreResponse {
    lastVersion: string;
}
export default function ChangeLog() {
    const rest = useRest();
    const version = useVersion().split('-')[0];

    const ref: MutableRefObject<HTMLFormElement> = useRef();

    const [lastVersion: string, setLastVersion: Function<string>] = useState(null);
    const [changes: Array, setChanges: Function<Array>] = useState([]);

    useEventListener('keydown', e => {
        // console.debug('ChangeLog() => Button press', e);
        if (e.key === 'Escape') {
            ref.current.classList.remove('visible');
        }
    });

    useEffect(() => {
        rest('/account/store?key=lastVersion', 'GET')
            .then((response: StoreResponse) => {
                console.debug('ChangeLog() => last version response:', response);
                // setLastVersion('1.5.1');
                setLastVersion(response.lastVersion ?? version);
            })
            .catch(() => {
                setLastVersion('1.5.2');
            })
    }, [rest, version]);

    useEffect(() => {
        if (lastVersion !== null && lastVersion !== version) {
            console.debug('ChangeLog() => lastVersion: "%s" !== version: "%s"', lastVersion, version);

            let data = extractChanges(lastVersion, version);
            setChanges(data.reverse());
            console.debug('ChangeLog() => changes: ', data);
            ref.current && ref.current.classList.add('visible');
        }
    }, [version, lastVersion, rest, ref]);

    function close(e: Event) {
        e.preventDefault();
        ref.current.classList.remove('visible');
        rest('/account/store', 'POST', JSON.stringify({key: 'lastVersion', value: version}));
        setLastVersion(null);
        setChanges([]);
    }

    return <ChangeLogPopup changes={changes} popupRef={ref} onClose={close}/>
}

