import moment from 'moment';
import type {Key} from '../interfaces';

export const filterKeys = (keys: Key[]) => {
    if (!keys || keys.length === 0) {
        return [];
    }
    return keys
        .filter(k => {
            if (k.has_package) {
                return true;
            } else {
                if (k.end_ts) {
                    const filterByEndTs = moment().subtract(14, 'days').unix();
                    return k.end_ts >= filterByEndTs;
                } else {
                    const calculatedEndTs = moment().add(k.duration, 'months').unix();
                    return calculatedEndTs >= moment().unix();
                }
            }
        })
}
