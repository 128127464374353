import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Circle, MapContainer, Marker, TileLayer, useMapEvents} from "react-leaflet";
import {toast} from "react-toastify";
import {CirclePin} from "../../components/Pin";
import {NumberField} from "../../components/RightPane/RightPane";
import useRest from "../../utils/rest";

import './MapModal.scss';
import "leaflet/dist/leaflet.css";

/**
 *
 * @param {Function} onAbort
 * @param {Position} initialPosition
 * @param {number} initialRadius
 * @returns {JSX.Element}
 * @constructor
 */
const MapModal = ({onAbort, initialPosition, initialRadius}) => {
    const {t} = useTranslation();
    const restService = useRest()

    const [position: {}, setPosition: Function<{}>] = useState({lat: initialPosition?.lat ?? 53.793307, lng: initialPosition?.lng ?? 20.436149});
    const [radius: number, setRadius: Function<number>] = useState(initialRadius ?? 30);
    const [zoom: number, setZoom: Function<number>] = useState(9);
    const [showInfoModal: number, setShowInfoModal: Function<number>] = useState(!!!(initialPosition && initialRadius));

    const submitHandler = async(e: Event) => {
        e.preventDefault();

        if (!position.lng || !position.lat || !radius) return;

        try {
            const data = {
                lat: position.lat,
                lng: position.lng,
                distance: parseFloat(radius),
            };
            const response = await restService('/clients/store', 'POST', data);
            toast.success(t('UPDATE_AREA_SUCCESS'));
            const event = new CustomEvent('area_updated', {detail: data});
            window.dispatchEvent(event)
            console.debug('MapModal::submitHandler', response);
        } catch (error) {
            toast.error(t('UPDATE_AREA_ERROR'));
            console.debug('MapModal::submitHandler', error);
        } finally {
            onAbort && onAbort();
        }
    }

    const handleInput = (e: Event) => {
        let {name, value} = e.target;
        if (!value) return;
        setPosition(Object.assign({}, position, {[name]: parseFloat(value).round(6)}));
    }

    function LocationMarker() {
        useMapEvents({
            click(e) {
                setPosition({
                    lat: e.latlng.lat.round(6),
                    lng: e.latlng.lng.round(6)
                });
            }
        })
        return <Marker position={position} icon={CirclePin}/>
    }

    return <>
        <div className='overlay' onClick={onAbort} />
        {showInfoModal && <div className='info-modal'>
            <header>
                <h3>{t('DEFAULT_AREA_INFO_HEADER')}</h3>
            </header>
            <main>{t('DEFAULT_AREA_INFO')}</main>
            <footer>
                <button className='green' onClick={() => setShowInfoModal(false)}>{t('CONFIRM')}</button>
            </footer>
        </div>}
        {!showInfoModal && <div className="map-modal">
            <header>
                <h3>{initialPosition ? t('EDIT_DEFAULT_AREA') : t('SET_DEFAULT_AREA')}</h3>
                <div>
                    <NumberField
                        id='lat'
                        label={t('LATITUDE')}
                        name='lat'
                        value={position.lat}
                        step="0.000001"
                        onChange={handleInput}
                    />
                    <NumberField
                        id='lng'
                        label={t('LONGITUDE')}
                        name='lng'
                        value={position.lng}
                        step="0.000001"
                        onChange={handleInput}
                    />
                    <div>
                        <NumberField
                            id='radius'
                            label={t('RADIUS')}
                            value={radius}
                            name='radius'
                            onChange={(e) => setRadius(e.target.value)}
                            step={0.01}
                            min={1}
                        />
                        <p>km</p>
                    </div>
                </div>
            </header>
            <main>
                <MapContainer center={position} zoom={zoom} onZoomEnd={e => setZoom(e.target._zoom)}>
                    <TileLayer url="https://{s}.tiles.treesat.io/roads/{z}/{x}/{y}.png" />
                    <LocationMarker />
                    <Circle center={position} radius={radius * 1000} />
                </MapContainer>
            </main>
            <footer>
                {onAbort && <button onClick={onAbort}>{t('common:CANCEL')}</button>}
                <button className="green" onClick={submitHandler}>
                    {t('common:SET')}
                </button>
            </footer>
        </div>}
    </>
}

export default MapModal;
