import {useEffect, useRef} from "react";

import './Modal.scss';

/**
 *
 * @param children {ReactNode}
 * @param onHide {Function}
 * @param id {string}
 * @param className {string}
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = ({children, onHide, id, className}) => {
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            ref.current.classList.add('visible');
        }, 10);
    }, []);

    const transitionHandler = (e: TransitionEvent) => {
        if (e.propertyName !== 'transform') {
            return;
        }
        if (!ref.current.classList.contains('visible')) {
            onHide && onHide();
        }
    };

    return <>
        <div className="overlay" onClick={() => {
            if (!onHide) return;
            onHide();
        }}></div>
        <div
            ref={ref}
            id={id}
            className={`modal-container ${className ? className : ''}`}
            onTransitionEnd={transitionHandler}
        >
            {children}
        </div>
    </>
};

export default Modal;
