import React from "react";

function r(url: string, method: 'GET'|'POST'|'DELETE'|'PUT', body: BodyInit | null, headers: HeadersInit | null): Promise<Object | string> {}

export const Rest = React.createContext(r);

export default function useRest() {
    const context = React.useContext(Rest)
    if (context === undefined) {
        throw new Error('useRest must be used within a Rest.Provider')
    }
    return context
}
