import React from "react";
import type {GroupContextInterface, GroupsContextInterface} from "../interfaces";

export const defaultGroup: GroupContextInterface = {
    id: 0,
    name: null,
    gates: [],
    users: [],
    setCurrentGroup: () => {}
}

export const CurrentGroup = React.createContext(defaultGroup);

export function useCurrentGroup(): GroupContextInterface {
    const currentGroup = React.useContext(CurrentGroup);
    if (currentGroup === undefined) {
        throw new Error('useCurrentGroup must be used within a Rest.Provider');
    }
    return currentGroup;
}

export const Groups = React.createContext([]);
export function useGroups(): GroupsContextInterface {
    const groups = React.useContext(Groups);
    if (groups === undefined) {
        throw new Error('useGroups must be used within a Rest.Provider');
    }
    return groups;
}
