import L from "leaflet";
import Pin from "../img/pin_fill_sharp_circle.png";

export default L.icon({
    iconUrl: Pin,
    iconSize: [36,51],
    iconAnchor: [18,51],
    popupAnchor: [0,51],
    tooltipAnchor: [0,51],
    className: 'map-pin'
});

export const CirclePin = L.divIcon({
    className: 'circle-pin'
});
