import React, {Suspense, lazy, useState, useEffect, useCallback} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "./i18n";

import "./App.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.min.css";
import ReactTooltip from "react-tooltip";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import {fetchEventSource} from "@microsoft/fetch-event-source";

import { defaultUser } from "./contexts/UserContext";
import { CurrentContext, defaultContext } from "./contexts/ContextContext";
import { CurrentUser } from "./contexts/UserContext";
import {CurrentGroup, Groups} from "./contexts/GroupContext";
import type {
    Context,
    GroupContextInterface,
    GroupsContextInterface,
    AccountStore,
    ClientStore, Key
} from "./interfaces";


import {useEventListener} from "./utils/hooks";

import {Rest} from "./utils/rest";
import {defaultGroup} from "./contexts/GroupContext";
import Help from "./modules/Help/Help";
import ChangeLog from "./modules/ChangeLog/ChangeLog";
import MapModal from "./modules/MapModal/MapModal";
import Newsletter from "./modules/Newsletter/Newsletter";
import c2oFavicon from "./img/favicon.ico";
import rbbFavicon from "./img/rbb_favicon.ico";
import {filterKeys} from "./utils/filterKeys";

const Login = lazy(() => import('./modules/Login/Login'));

const Sidebar = lazy(() => import('./modules/Sidebar/Sidebar'));
const Navigation = lazy(() => import('./modules/Navigation/Navigation'));

const Dashboard = lazy(() => import('./modules/Dashboard/Dashboard'));
const Workers = lazy(() => import('./modules/Workers/Workers'));
const Members = lazy(() => import('./modules/Members/Members'));
const Clients = lazy(() => import('./modules/Clients/Clients'));
const Paramedics = lazy(() => import('./modules/Paramedics/Paramedics'));
const RbbGates = lazy(() => import("./modules/RbbGates/RbbGates"));
const Keys = lazy(() => import('./modules/Keys/Keys'));
const Limits = lazy(() => import('./modules/Limits/Limits'));
const Gates = lazy(() => import('./modules/Gates/Gates'));
const Map = lazy(() => import('./modules/GateMap/GateMap'));
const Devices = lazy(() => import('./modules/Devices/Devices'));
const Accesses = lazy(() => import('./modules/Accesses/Accesses'));
const Cart = lazy(() => import('./modules/Cart/Cart'));
const Notifications = lazy(() => import('./modules/Notifications/Notifications'));
const Account = lazy(() => import('./modules/Account/Account'));

Moment.globalFormat = 'YYYY/MM/DD HH:mm:ss'


function App() {
    const [currentUser: CurrentUser, setCurrentUser: Function] = useState(defaultUser);
    const [currentContext: Context, setCurrentContext: Function] = useState(defaultContext);
    const [currentGroup: GroupContextInterface, setCurrentGroup: Function<GroupContextInterface>] = useState(defaultGroup);
    const [groups: GroupsContextInterface, setGroups: Function<GroupsContextInterface>] = useState([]);
    const [showMapModal: boolean, setShowMapModal: Function<boolean>] = useState(false);
    const [showNewsletterModal: boolean, setShowNewsletterModal: Function<boolean>] = useState(false);
    currentUser.setCurrentUser = setCurrentUser;
    currentContext.setCurrentContext = setCurrentContext;
    currentGroup.setCurrentGroup = setCurrentGroup;
    groups.setGroups = setGroups;

    const { t } = useTranslation('common');

    if (process.env.NODE_ENV !== "development") {
        console.log = () => {};
        console.debug = () => {};
    }

    let restService = window.rest = useCallback(async (url: string, method: string, body: BodyInit | null, headers: HeadersInit | null) => {
        let _method = 'GET';
        if (method) {
            _method = method;
        }
        // console.debug('restService() => %s %s', _method.toLocaleUpperCase(), url);

        let _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'C2O-Application': 'c2o/web',
        }
        if (currentUser.token) {
            _headers = {
                ..._headers,
                'Token': currentUser.token
            };
        }
        if (currentContext.id) {
            _headers = {
                ..._headers,
                'C2O-User-Context': currentContext.id,
            };
        }

        if (headers) {
            _headers = {..._headers, ...headers};
            if (headers['Content-Type'] === undefined) {
                delete _headers['Content-Type'];
            }
        }

        let _opts = {
            method: _method,
            headers: _headers,
            referrerPolicy: 'no-referrer',
        };
        if (body) {
            // console.debug('restService() => %s %s :: Body type:', _method.toLocaleUpperCase(), url, typeof body);
            if (typeof body === 'string') {
                // console.debug('Passing body directly');
                _opts.body = body;
            } else if (!_headers['Content-Type']) {
                _opts.body = body;
            } else {
                // console.debug('Using JSON.stringify on body');
                _opts.body = JSON.stringify(body);
            }
        }
        let response;
        console.debug('restService() => %s %s <<', _method.toLocaleUpperCase(), url, _opts);
        try {
            response = await fetch(process.env.REACT_APP_API_URL.trimEnd('/') + url, _opts);
        } catch (err) {
            return Promise.reject('err_network_error');
        }
        const responseBody = await response.text();

        let content: Object|String;
        // try to parse JSON data
        try {
            content = JSON.parse(responseBody);
        } catch (e) {
            content = responseBody;
        }
        if (content instanceof String) {
            content = content
                .replace(/^[" ]*/,'')
                .replace(/[" ]*$/, '');
        }
        console.debug('restService() => %s %s >>', _method.toLocaleUpperCase(), url, content);

        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(content);
        } else if (response.status === 403) {
            return Promise.reject(content ?? 'err_access_denied');
        } else {
            return Promise.reject(content);
        }
    }, [currentContext.id, currentUser.token]);

    useEffect(() => {
        if (!currentUser.token || !currentContext.id || currentContext.type === 'dealer') {
            return;
        }
        const abortController = new AbortController();

        const fetchData = async () => {
            await fetchEventSource(`${process.env.REACT_APP_API_URL.trimEnd('/')}/eventsource/all`, {
                method: 'GET',
                headers: {
                    'Accept': 'text/event-stream',
                    'Content-Type': 'text/event-stream',
                    'C2O-Application': 'c2o/web',
                    'Token': currentUser.token,
                    'C2O-User-Context': currentContext.id,
                },
                signal: abortController.signal,
                openWhenHidden: true,
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        console.debug('Event source :: connection made ', res);
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.error('Event source :: Client side error ', res);
                    }
                },
                onmessage(event) {
                    const data = JSON.parse(event.data);
                    console.debug('Event source :: message ', data);

                    if (data.event === 'gate_status') {
                        const {gate_id, status} = data.message;

                        window.dispatchEvent(new CustomEvent('gate_status_changed', {
                            detail: {
                                gate_id: gate_id,
                                status: status
                            }
                        }));
                    }
                },
                onclose() {
                    console.debug('Event Source :: Connection closed by the server');
                    abortController.abort();
                },
                onerror(err) {
                    console.error('Event Source :: There was an error from server', err);
                },
            });
        };
        fetchData();

        return () => {
            abortController.abort();
        }
    }, [currentContext.id, currentUser.token, currentContext.type]);

    useEffect(() => {
        currentUser.id !== null && restService('/account/store', 'POST', JSON.stringify({key: 'lastVisit', value: Date.now()}));
    }, [restService, currentUser.id]);

    // Update key count
    useEffect(() => {
        if (currentContext.id === null) return;
        let isMounted = true;

        restService('/keys', 'GET')
            .then((keys: Key[]) => {
                if (isMounted) {
                    let _keys: Key[] = [];
                    if (keys instanceof Array) {
                        _keys = filterKeys(keys)
                        window.dispatchEvent(new CustomEvent('context_data_update', {detail: {keys_count: _keys.length}}));
                    }
                }
            });

        return () => {
            isMounted = false;
        }
    }, [restService, currentContext.id]);

    const reloadContext = () => {
        if (!currentContext.id) return;
        restService('/account/contexts/' + currentContext.id)
            .then((context: Context) => {
                setCurrentContext({...currentContext, ...context});
                setCurrentUser({...currentUser, contexts: currentUser.contexts.map(c => c.id === context.id ? context : c), unread_notifications: context.unread_notifications});
            });
    };
    useEventListener('context_data_update', (e: CustomEvent) => {
        const update = e.detail;
        const context = {...currentContext, ...update};
        console.debug('App::[context_data_update] => %O => %O', currentContext, context);
        setCurrentContext(context);
        setCurrentUser({...currentUser, contexts: currentUser.contexts.map(c => c.id === context.id ? context : c)});
    });
    useEventListener('user_data_update', (e: CustomEvent) => {
        const update = e.detail;
        const user = {...currentUser, ...update};
        console.debug('App::[user_data_update] => %O => %O', currentUser, user);
        setCurrentUser(user);
    });
    useEventListener('member_created', reloadContext);
    useEventListener('member_deleted', reloadContext);
    useEventListener('worker_created', reloadContext);
    useEventListener('worker_deleted', (e: CustomEvent) => {
        let id: number = e.detail;
        if (id === currentUser.id) {
            // worker removed himself from current context -> filter it out!
            setCurrentUser({...currentUser, contexts: currentUser.contexts.filter(c => c.id !== currentContext.id)});
            setCurrentContext(defaultContext);
        } else {
            reloadContext(e);
        }
    });
    useEventListener('gate_created', reloadContext);
    useEventListener('gate_deleted', reloadContext);
    useEventListener('client_created', reloadContext);
    useEventListener('notifications_read', (e: CustomEvent) => {
        let ids: number[] = e.detail;
        // make assumption
        setCurrentUser({...currentUser, unread_notifications: (currentUser.unread_notifications - ids.length)});
        // try to fetch :D
        reloadContext();
    });
    useEventListener('notification_received', () => {
        reloadContext();
    });
    useEventListener('short_access_created', reloadContext);
    useEventListener('short_access_deleted', reloadContext);
    useEventListener('area_updated', () => setShowMapModal(false));

    const fetchOffersCount = useCallback(() => {
        restService('/purchase_offers/count?status=new')
            .then((response: { count: number }) => {
                setCurrentContext(prev => ({...prev, offers_count: response.count}));
            })
            .catch((reason) => {
                console.error('App::cannot fetch offers count', reason);
                setCurrentContext(prev => ({...prev, offers_count: 0}));
            });
    }, [restService]);

    useEventListener('cart_updated', fetchOffersCount);

    useEffect(() => {
        if (currentContext.type === 'rbb_dispatcher') {
            restService('/clients/store').then((response: ClientStore) => {
                if (response.lat && response.lng && response.distance) {
                    setShowMapModal(false);
                } else {
                    setShowMapModal(true);
                }
            });
        }
    }, [currentContext, restService]);

    // message processor
    useEffect(() => {
        if (!currentUser.id) {
            // do not setup message processor until user logs in
            return;
        }
        const messageProcessor = async (message: MessageEvent) => {
            console.debug('App()::[message] => FCM received', message);
            // message.preventDefault();
            const title: string = message.data.data['notification.name'];
            if (title) {
                setCurrentUser({...currentUser, unread_notifications: currentUser.unread_notifications + 1});
                toast.info(t('NEW_NOTIFICATION'), { autoClose: 1800 });
                let body = {...message.data.data};
                delete body['notification.name'];
                let event;
                if (title === 'personal_invitation_declined') {
                    event = new CustomEvent('user_deleted', {detail: parseInt(body.user_id)});
                } else if (title === 'device_status_changed') {
                    event = new CustomEvent('device_updated', {detail: {id: body.id, active: body.active === 'true'}});
                } else if (title === 'worker_removed') {
                    event = new CustomEvent('worker_deleted', {detail: parseInt(body.id)});
                } else if (['member_updated', 'worker_updated'].includes(title)) {
                    event = new CustomEvent(title, {detail: {...body, id: parseInt(body.id), client_id: parseInt(body.client_id)}});
                } else if (['group_created', 'group_updated'].includes(title)) {
                    try {
                        let g = await restService('/groups/' + body.id);
                        event = new CustomEvent(title, {detail: g});
                    } catch (e) {
                        console.warn('Could not read group from server! No event will be emitted! FCM: %O, error: %O', message, e);
                    }
                } else if (title === 'group_removed') {
                    event = new CustomEvent(title, {detail: parseInt(body.id)});
                } else {
                    event = new CustomEvent(title, {detail: body});
                }
                // if (title === 'keys_created') {
                //     event = new CustomEvent('keys_created', {detail: body});
                // }
                if (event) {
                    console.debug('App() :: dispatching CustomEvent:', event);
                    window.dispatchEvent(event);
                }
                window.dispatchEvent(new CustomEvent('notification_received', {detail: body}));
            }

        }
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("message", messageProcessor);
        }
        return () => {
            if (navigator.serviceWorker) {
                navigator.serviceWorker.removeEventListener("message", messageProcessor);
            }
        }
    }, [t, currentUser, restService]);

    // auto select context if only one is available
    useEffect(() => {
        if (currentContext.id === null && currentUser.id !== null && currentUser.contexts.length === 1) {
            // User is logged in, but no context is selected and only one context is available
            setCurrentContext(currentUser.contexts[0]);
        }
    }, [currentUser, currentContext]);

    // clean current context if currentUser is not set
    useEffect(() => {
        if (!currentUser || !currentUser.id) {
            setCurrentContext(defaultContext);
            setCurrentGroup(defaultGroup);
        } else {
            console.debug('App()::[currentUser] =>', currentUser);
        }
    }, [currentUser]);

    // clear group if current context is dealer context
    useEffect(() => {
        if (currentContext.type === "dealer") {
            setCurrentGroup(defaultGroup);
        }
    }, [currentContext]);

    // change favicon if current context changed
    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (currentContext.type === 'rbb_dispatcher') {
            link.href = rbbFavicon;
        } else {
            link.href = c2oFavicon;
        }
    }, [currentContext]);

    // const prevContext: Context = usePrevious(currentContext);
    // useEffect(() => {
    //     if (prevContext && (prevContext.id === currentContext.id)) {
    //         // do nothing if context id remains unchanged
    //         return;
    //     }
    //     reloadContext(new CustomEvent('context_change'));
    // }, [prevContext, currentContext, reloadContext]);
    useEffect(() => {
        console.debug('App()::[currentContext] =>', currentContext);
    }, [currentContext]);
    useEffect(() => {
        console.debug('App()::[currentGroup] =>', currentGroup);
    }, [currentGroup]);

    const checkNewsletter = useCallback(async () => {
        if (!currentContext.type) return;

        try {
            const response: AccountStore = await restService('/account/store');

            if (currentContext.type === 'rbb_dispatcher') {
                if (response.rbb_newsletter_modal) {
                    setShowNewsletterModal(false);
                } else {
                    setShowNewsletterModal(true);
                    const accountStore = await restService('/account/store', 'POST', {key: 'rbb_newsletter_modal', value: true});
                    console.debug('Account store updated', accountStore);
                }
            } else if (currentContext.type === 'dealer' || currentContext.type === 'manager') {
                if (response.c2o_newsletter_modal) {
                    setShowNewsletterModal(false);
                } else {
                    setShowNewsletterModal(true);
                    const accountStore = await restService('/account/store', 'POST', {key: 'c2o_newsletter_modal', value: true});
                    console.debug('Account store updated', accountStore);
                }
            }
        } catch (error) {
            console.debug('App::checkNewsletter', error);
        }
    }, [currentContext.type, restService]);

    useEffect(() => {
        checkNewsletter();
    }, [checkNewsletter]);

    useEffect(() => {
        if (currentContext.type !== 'manager' && currentContext.type !== 'dealer') return;
        fetchOffersCount();
    }, [currentContext.type, fetchOffersCount]);

    return (
        <>
            <ToastContainer autoClose={2500}/>
            <div className={"App" + (currentUser.id === null ? '' : ' authenticated') + (' ctx-' + currentContext.type) + (currentContext.rbb_context ? ' ' + currentContext.rbb_context : '')}>
                <Router>
                    <Suspense fallback={<div className="loader">&nbsp;</div>}>
                        {currentUser.id === null
                            ? <Login setCurrentUser={setCurrentUser}/>
                            : <>
                                <CurrentUser.Provider value={currentUser}>
                                <CurrentContext.Provider value={currentContext}>
                                <CurrentGroup.Provider value={currentGroup}>
                                <Groups.Provider value={groups}>
                                <Rest.Provider value={restService}>
                                    <ReactTooltip effect="solid"/>
                                    <ChangeLog/>
                                    {showNewsletterModal && <Newsletter onHide={() => setShowNewsletterModal(false)}/>}
                                    <Sidebar/>
                                    {showMapModal && currentContext.role === 'manager' && <MapModal/>}
                                    <Navigation unreadNotifications={currentContext && currentContext.id ? currentContext.unread_notifications : 0}/>
                                    <Switch>
                                        <Route path="/account" exact>
                                            <Account/>
                                        </Route>
                                        <Route path="/account/settings">
                                            <main id="account-settings"><h1>Ustawienia <span role="img" aria-label="">⚙️</span></h1></main>
                                        </Route>
                                        <Route path="/account/logout">
                                            <main id="account-logout"><h1>Logout <span role="img" aria-label="">👋️</span></h1></main>
                                        </Route>

                                        <Route path="/workers">
                                            <Workers/>
                                        </Route>
                                        <Route path="/members">
                                            <Members/>
                                        </Route>
                                        <Route path="/clients">
                                            <Clients currentContext={currentContext}/>
                                        </Route>
                                        <Route path="/paramedics">
                                            <Paramedics/>
                                        </Route>
                                        <Route path="/rbb/gates">
                                            <RbbGates/>
                                        </Route>
                                        <Route path="/gates">
                                            <Gates/>
                                        </Route>
                                        <Route path="/map">
                                            <Map/>
                                        </Route>
                                        <Route path="/devices">
                                            <Devices currentContext={currentContext}/>
                                        </Route>
                                        <Route path="/keys">
                                            <Keys currentContext={currentContext}/>
                                        </Route>
                                        <Route path="/limits">
                                            <Limits/>
                                        </Route>
                                        <Route path="/accesses">
                                            <Accesses/>
                                        </Route>
                                        <Route path="/cart">
                                            <Cart/>
                                        </Route>
                                        <Route path="/notifications">
                                            <Notifications/>
                                        </Route>
                                        <Route path="/issues">
                                            <main id="issues"><h1>Zgłoszenia <span role="img" aria-label="">📝</span></h1></main>
                                        </Route>
                                        <Route path="/help">
                                            <Help/>
                                        </Route>
                                        <Route path="/">
                                            <Dashboard currentContext={currentContext} setCurrentUser={setCurrentUser}/>
                                        </Route>
                                    </Switch>
                                </Rest.Provider>
                                </Groups.Provider>
                                </CurrentGroup.Provider>
                                </CurrentContext.Provider>
                                </CurrentUser.Provider>
                            </>
                        }
                    </Suspense>
                </Router>
            </div>
        </>
    );
}


export default App;
