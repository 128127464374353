import {useState} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

import useCurrentContext from "../../contexts/ContextContext";
import useCurrentUser from "../../contexts/UserContext";
import {TextField} from "../../components/RightPane/RightPane";
import Modal from "../../components/Modal/Modal";
import useRest from "../../utils/rest";
import IconInfo from "../../img/icon-info";

import "./Newsletter.scss";

/**
 * @param {Function} onHide
 * @param {Function} onSuccess
 * @returns {JSX.Element}
 * @constructor
 */

const Newsletter = ({onHide, onSuccess}) => {
    const { t } = useTranslation(['common', 'users']);
    const restService = useRest();
    const currentContext = useCurrentContext();
    const currentUser = useCurrentUser();

    const [agreement: boolean, setAgreement: Function<boolean>] = useState(false);
    const [firstName: string, setFirstName: Function<string>] = useState('');

    const onConfirm = async () => {
        if (!agreement) {
            onHide && onHide();
            return;
        }
        if ((!currentUser.first_name && firstName.length === 0)) return;
        if (firstName.trim().length > 0) {
            const response = await restService('/account', 'PUT', JSON.stringify({first_name: firstName.trim()}));
            if (response && response.first_name) {
                window.dispatchEvent(new CustomEvent('user_data_update', {detail: response}));
            }
            console.debug('Newsletter::updating user first_name', response);
        }

        try {
            const group = currentContext.type === 'rbb_dispatcher' ? 'rbb' : 'click2open';
            const response = await restService(`/account/newsletter`, 'POST', {group: group})
            toast.success(t('SUBSCRIBE_SUCCESS'));
            onSuccess && onSuccess();
            onHide && onHide();
            console.debug('Newsletter::onConfirm', response);
        } catch (error) {
            toast.error(t('SUBSCRIBE_ERROR'));
            console.debug('Newsletter::onConfirm', error);
        }
    }

    return <Modal
        id="newsletter_modal"
        className="newsletter"
    >
        <header>{t('SUBSCRIBE')}</header>
        <main>
            {!currentUser.first_name && <TextField
                id="first_name"
                name="first_name"
                label={t('users:FIRST_NAME')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value.trim())}
                required={true}
            />}
            <div>
                <input
                    type="checkbox"
                    id="agreement"
                    value={agreement}
                    onChange={(e) => setAgreement(e.target.checked)}
                />
                <label htmlFor="agreement">{t('NEWSLETTER_AGREEMENT')}</label>
                <span title={t('NEWSLETTER_INFO')}><IconInfo/></span>
            </div>
        </main>
        <footer>
            <button onClick={onHide}>
                {t('common:CANCEL')}
            </button>
            <button onClick={onConfirm} className="green">
                {t('common:SAVE')}
            </button>
        </footer>
    </Modal>
}

export default Newsletter;
