// OOF size large 😆

import React from "react";
import type {Context} from "../interfaces";

export const defaultContext: Context = {
    id: null,
    name: null,
    type: null,
    role: null,
    setCurrentContext: () => {}
}

export const CurrentContext = React.createContext(defaultContext);

export default function useCurrentContext(): Context {
    const currentContext = React.useContext(CurrentContext);
    if (currentContext === undefined) {
        throw new Error('useCurrentContext must be used within a Rest.Provider')
    }
    return currentContext
}
