const IconCheckboxYes = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g transform="translate(-7256 -1516)">
            <rect width="20" height="20" rx="5" transform="translate(7256 1516)" fill="#25354d"/>
            <path d="M277.092,228l-7.69,8h0l-4.8-5,1.922-2,2.884,3,5.767-6Z" transform="translate(6995.403 1295)" fill="#fff"/>
        </g>
    </svg>
}

export default IconCheckboxYes;
