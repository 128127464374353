import React from "react";
import type {CurrentAccount} from "../interfaces";

/*
context:
{
    "id": 1,
    "name": "Treesat",
    "type": "manager"
}
 */
export const defaultUser = {
    contexts: [],
    email: null,
    first_name: null,
    last_name: null,
    login: null,
    phone_number: null,
    token: null,
    id: null,
    unread_notifications: 0,
    setCurrentUser : () => {}
};
export const CurrentUser = React.createContext(defaultUser);

export default function useCurrentUser(): CurrentAccount {
    const c = React.useContext(CurrentUser);
    if (c === undefined) {
        throw new Error('useCurrentUser must be used within a Rest.Provider')
    }
    return c
}
