import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
// import * as serviceWorker from './serviceWorker';

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
        .then(async registration => {
            await registration.unregister();
            console.debug('index.js => SW unregistered');
        })
        .catch(error => {
            console.error('index.js =>', error.message);
        });

    // required to handle background Pushes - we do not need them at the moment
    // navigator.serviceWorker
    //     .register("./firebase-messaging-sw.js")
    //     .then(function(registration) {
    //         console.debug("index.js => Registration successful, scope is:", registration.scope);
    //     })
    //     .catch(function(err) {
    //         console.error("index.js => Service worker registration failed, error:", err);
    //     });
}

ReactDOM.render(
  <React.StrictMode>
      <Suspense fallback={<div className="loader">&nbsp;</div>}>
      <App />
      </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
